import { SnackbarService } from '@swe/shared/providers/snackbar';

import { useCallback } from 'react';

import PasswordRecovery from 'common/containers/password-recovery';
import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate } from 'common/router';

const PasswordChange = () => {
  const { user } = useCurrentUser();
  const navigate = useRouterNavigate();

  const handleSuccess = useCallback(() => {
    SnackbarService.push({
      type: 'success',
      message: 'Password has been changed successfully',
    });
    return navigate(-1);
  }, [navigate]);

  return (
    <PasswordRecovery
      email={user?.email}
      onSuccess={handleSuccess}
      dense
    />
  );
};

export { PasswordChange };
