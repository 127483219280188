import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import PageHeader from 'common/components/page-header';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { Routes } from 'common/router/constants';
import { PasswordChange } from 'domains/profile/containers/privacy-and-security/containers/password-change';

const TITLE = 'Password Change';

const ProfilePasswordChangePage: ApplicationPage = () => {
  return (
    <Page {...DEFAULT_AUTHORIZED_PAGE_PROPS}>
      <PageHeader
        title={TITLE}
        defaultBackRoute={Routes.Profile}
        enableMarginBottom
      />
      <PasswordChange />
    </Page>
  );
};

ProfilePasswordChangePage.getMeta = () => ({ title: TITLE });

ProfilePasswordChangePage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfilePasswordChangePage;
